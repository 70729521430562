import React, { useContext } from "react"

import MedicineBox from "elements/MedicineBox"
import RefillSearch from "../../../../../Search/RefillSearch"
import AddMoreMedicinesButton from "./AddMoreMedicinesButton"

import { RefillsContext } from "../../../RefillsContext/RefillsContext"

const AddMedicinesSection = ({
  showSearch,
  setShowSearch,
  searchComponentProps,
}) => {
  const { refillsState } = useContext(RefillsContext)

  switch (true) {
    case !!showSearch:
      return (
        <MedicineBox
          handleDelete={
            refillsState?.updatedPrescription?.cancelledMedicines?.length === 0
              ? null
              : () => setShowSearch(false)
          }
        >
          <RefillSearch
            searchType="drug"
            label="Search Medicines"
            {...searchComponentProps}
          />
        </MedicineBox>
      )
    case !showSearch &&
      refillsState?.updatedPrescription?.cancelledMedicines?.length > 0:
      return (
        <AddMoreMedicinesButton
          handleClick={() => {
            setShowSearch(true)
          }}
        />
      )
    default:
      return null
  }
}

export default AddMedicinesSection
