import React from "react"

const AddMoreMedicinesButton = ({ handleClick }) => {
  return (
    <button
      className="button is-text has-text-primary has-text-weight-bold is-size-6 is-underlined mt-1"
      onClick={handleClick}
      type="button"
    >
      + Cancel more medicines
    </button>
  )
}

export default AddMoreMedicinesButton
