import React, { Fragment, useContext, useEffect, useState } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import MedicineBox from "elements/MedicineBox"
import ActionButtons from "elements/ActionButtons"
import AddMedicinesSection from "./components/AddMedicinesSection"
import MedEnsureModal from "../../MedEnsureModal"

import {
  zendeskUploadFiles,
  generateUploadFiles,
} from "../../../../../services/zendeskService"
import { formSchema } from "../../../utils/formSchema"
import { isBrowser } from "../../../../../services/general"
import { RefillsContext } from "../../RefillsContext/RefillsContext"
import { parseFormField } from "../../../../../services/airtable"
import { generateFormField } from "../../../../Elements/Form/services/form"
import { generateInitialValues } from "../../../../../services/context"
import { useMedensureProducts } from "../../../MedEnsureContext/hooks/useMedEnsureProducts"
import { reformatAirtableData } from "../../services/reformatAirtableData"
import { AppContext } from "../../../../../context/AppContext"
import { getBackPage } from "../../services/getPreviousPage"

const RefillCancellationForm = ({ pageContext, location }) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const client = urlSearchParams.get("client") || ""
  const { refillsState, refillsDispatch } = useContext(RefillsContext)
  const { state, dispatch } = useContext(AppContext)
  const [filesUploaded, setFilesUploaded] = useState(refillsState.documents)
  const [showSearch, setShowSearch] = useState(
    refillsState?.updatedPrescription?.cancelledMedicines?.length === 0
  )
  // redirect to personal details if email is empty
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      refillsState?.updatedPrescription?.email?.length === 0
    ) {
      navigate("/medensure/refills/")
    }
  }, [refillsState?.updatedPrescription?.email])
  const data = useMedensureProducts()
  let medensureFormulary = data?.allAirtableallSitelMeProducts?.nodes.map(
    (node) => node.data
  )
  medensureFormulary = medensureFormulary.map(reformatAirtableData)

  medensureFormulary = medensureFormulary.reduce(
    (medicines, currentProduct) => {
      let currentDrug = medicines.find(
        (medicine) => medicine.drugName === currentProduct.drugName
      )
      if (!currentDrug) medicines = [...medicines, currentProduct]
      return medicines
    },
    []
  )

  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  let medensureServices = []
  if (isBrowser())
    medensureServices =
      JSON.parse(sessionStorage.getItem("medensureServices")) || []

  const handleSubmit = (values) => {
    if (refillsState?.updatedPrescription?.cancelledMedicines?.length === 0)
      return values
    refillsDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...refillsState,
        updatedPrescription: {
          ...refillsState.updatedPrescription,
          ...values,
          cancelledMedicines: [
            ...refillsState.updatedPrescription.cancelledMedicines,
          ],
        },
      },
    })

    switch (true) {
      case medensureServices.includes("Book a free teleconsult"):
        navigate("/medensure/refills/health-survey")
        return
      default:
        navigate("/medensure/refills/summary")
        return
    }
  }

  const handleOnSelect = (item) => {
    if (!!item) {
      const medicineList = [
        ...refillsState.updatedPrescription.cancelledMedicines,
      ]
      if (!item.molecule) medicineList.push({ ...item })
      else medicineList.push({ ...item })
      refillsDispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...refillsState,
          updatedPrescription: {
            ...refillsState.updatedPrescription,
            cancelledMedicines: medicineList,
          },
        },
      })
      setShowSearch(false)
    }
  }

  let selectedMedicines =
    refillsState.updatedPrescription.cancelledMedicines || []
  selectedMedicines = selectedMedicines.map((medicine) => {
    return medicine.drugName
  })

  let hasMultipleSegment = medensureServices?.includes(
    "Book a free teleconsult"
  )

  const searchComponentProps = {
    placeholder: "Metformin",
    dataSource: {
      type: "json",
      data: medensureFormulary.filter(
        (medicine) => !selectedMedicines.includes(medicine.drugName)
      ),
      lookupKeys: ["molecule", "drugName"],
    },
    withIcon: true,
    onSelect: handleOnSelect,
    message: null,
  }

  const handleRemoveMedicine = (index) => {
    const medicineList = [
      ...refillsState.updatedPrescription.cancelledMedicines,
    ]
    medicineList.splice(index, 1)
    refillsDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...refillsState,
        updatedPrescription: {
          ...refillsState.updatedPrescription,
          cancelledMedicines: medicineList,
        },
      },
    })
    if (medicineList.length === 0) setShowSearch(true)
  }

  useEffect(() => {
    refillsDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      path="/medensure"
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <Formik
          enableReinitialize
          initialValues={{
            ...generateInitialValues({ fields: formFields }),
            ...refillsState.updatedPrescription,
            uploadPrescription: [...(refillsState.documents || [])],
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  id={section?.sectionId || ""}
                  className="mt-1 mb-3"
                >
                  {section?.message && (
                    <Message color={section?.messageColor || "light"}>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: section?.message,
                        }}
                      />
                    </Message>
                  )}{" "}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            setFilesUploaded,
                            formFields: section?.fields,
                            formField: {
                              ...field,
                              disabled:
                                field.name === "company" || !!field.disabled,
                            },
                            values,
                            setFieldValue,
                            context: {
                              refillsState,
                              refillsDispatch,
                            },
                            errors,
                            submitCount,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              <Section title={"Discontinued Medicines"} className="mt-1 mb-3">
                <Message color="warning">
                  Let us know which medicines you no longer want to receive.
                </Message>
                {refillsState?.updatedPrescription?.cancelledMedicines?.map(
                  (medicine, index) => (
                    <MedicineBox
                      handleDelete={() => handleRemoveMedicine(index)}
                    >
                      <p className="is-size-5 has-text-primary has-text-weight-bold">
                        {medicine.molecule}
                      </p>
                      <p className="is-size-6">
                        {medicine?.molecule === "Vitamin B complex"
                          ? ""
                          : medicine.strength}{" "}
                        {medicine.form}
                      </p>
                    </MedicineBox>
                  )
                )}
                <AddMedicinesSection
                  showSearch={showSearch}
                  setShowSearch={setShowSearch}
                  searchComponentProps={searchComponentProps}
                />
                {refillsState?.updatedPrescription?.cancelledMedicines
                  ?.length === 0 &&
                  submitCount > 0 && (
                    <p className="has-text-danger help mt-1">
                      Your medicine list cannot be empty
                    </p>
                  )}
              </Section>
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{
                  label: "Back",
                  link: getBackPage(medensureServices, "Cancel refills"),
                }}
                submit={{
                  label: hasMultipleSegment ? "Next" : "Summary",
                  disabled:
                    refillsState?.updatedPrescription?.cancelledMedicines
                      ?.length === 0,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default RefillCancellationForm
